import React, { useState } from "react";
import style from "./../../didactic.module.css";
import Footer from "../../../Footer";
import Header from "../../HeaderDidactic";
import { useCourses } from "../../../../CoursesContext";
import CreateCourseForm from "./CreateCourse";
import CreateTopicForm from "./CreateTopic";
import CreateSectionForm from "./CreateSection";
import CreateTemplateForm from "./CreateTemplate";

const links = [
  { name: "Strona główna", url: "/" },
  { name: "Wszystkie kursy", url: "/course" },
  { name: "Stwórz nowy kurs", url: "/course/CreateCourse" },
];

// Typy dla propsów
export interface FormData {
  templateName?: string;
  templateType: string;
  textContent?: string;
  link?: string;
  linkName?: string;
  imageLink?: string;
  imageSourceName?: string;
  imageSourceLink?: string;
  language?: string;
  codeContent?: string;
  download?: string;
  downloadName?: string;
}

// Uniwersalna funkcja handleChange
export const handleChange = (
  e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
  setState: React.Dispatch<React.SetStateAction<any>>,
  stateUpdater: (value: any) => void
) => {
  const { name, value } = e.target;
  stateUpdater(value);
  setState((prevState: void) => ({
    ...(prevState as any),
    [name]: value
  }));
};

// Użycie funkcji handleChange w CreateForm.tsx
export const useFormHandlers = (
  formData: FormData,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  setSelectedCourse: React.Dispatch<React.SetStateAction<number | null>>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<number | null>>,
  setSelectedSection: React.Dispatch<React.SetStateAction<number | null>>,
  setSelectedTemplate: React.Dispatch<React.SetStateAction<number | null>>
) => {
  return {
    handleCourseChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const courseId = Number(e.target.value);
      setSelectedCourse(courseId);
      setSelectedTopic(null);
      setSelectedSection(null);
      setSelectedTemplate(null);
      setFormData(prevData => ({
        ...prevData,
        selectedCourse: courseId,
        selectedTopic: null,
        selectedSection: null,
        selectedTemplate: null
      }));
    },
    handleTopicChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const topicId = Number(e.target.value);
      setSelectedTopic(topicId);
      setSelectedSection(null);
      setSelectedTemplate(null);
      setFormData(prevData => ({
        ...prevData,
        selectedTopic: topicId,
        selectedSection: null,
        selectedTemplate: null
      }));
    },
    handleSectionChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sectionId = Number(e.target.value);
      setSelectedSection(sectionId);
      setSelectedTemplate(null);
      setFormData(prevData => ({
        ...prevData,
        selectedSection: sectionId,
        selectedTemplate: null
      }));
    },
    handleTemplateChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const templateId = Number(e.target.value);
      setSelectedTemplate(templateId);
      setFormData(prevData => ({
        ...prevData,
        selectedTemplate: templateId
      }));
    }
  };
};

export default function CreateForm() {
  const { courses, sections, topicSections, sectionTemplates, templates, loading, error, AddCourse, AddTopic, AddSection, AddTemplate, AddTopicSection, AddSectionTemplate  } = useCourses();
  const [activeForm, setActiveForm] = useState<string | null>(null);
  

  if (!courses || !sections || !topicSections || !sectionTemplates || !templates) {
    return <div>Some data is missing</div>;
  }

  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header links={links} />
      <div className="container">
        <div className="row justify-content-center">
          <div className={`${style.didacticBlock} col-12 col-md-12 text-center rounded mt-4 mb-4`}>
            <h1>Stwórz nowy {activeForm}</h1>
          </div>
        </div>

        <div className={`${style.didacticBlock} col-12 col-md-12 text-center rounded mb-4 p-2`}>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center">
              <button onClick={() => setActiveForm("course")}>Nowy kurs</button>
              <button onClick={() => setActiveForm("topic")}>Nowy temat</button>
              <button onClick={() => setActiveForm("section")}>Nowa sekcja</button>
              <button onClick={() => setActiveForm("template")}>Nowy dział</button>
            </div>
          </div>
 
          {activeForm === "course" && <CreateCourseForm onSubmitCourse={AddCourse}/>}
          {activeForm === "topic" && <CreateTopicForm courses={courses} onSubmitTopic={AddTopic}/>}
          {activeForm === "section" && <CreateSectionForm courses={courses} sections={sections} onSubmitSection={AddSection} onSubmitTopicSection={AddTopicSection}/>}
          {activeForm === "template" && <CreateTemplateForm courses={courses} sections={sections} templates={templates} topicSections={topicSections} onSubmitSectionTemplate={AddSectionTemplate} onSubmitTemplate={AddTemplate}/>}
        </div>
      </div>
      <Footer />
    </>
  );
}
