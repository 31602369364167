import { useState } from "react";
import {Course, Section, TopicSection, SectionTemplate, Template, useSendRequest} from '../CoursesContext';

const domainAddress = process.env.REACT_APP_API_URL;

export const usePutMethods = () => {
  const [courses, setCourses] = useState<Course[] | null>(null);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [topicSections, setTopicSections] = useState<TopicSection[] | null>(null);
  const [sectionTemplates, setSectionTemplates] = useState<SectionTemplate[] | null>(null);
  const [templates, setTemplates] = useState<Template[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {SendRequest} = useSendRequest();

    const UpdateCourse = async (courseId: number, name: string, description: string) => {
        try {
          await SendRequest(`${domainAddress}/backend/api/course`, "PUT", {
            id: courseId,
            name,
            description,
          });
          setCourses(
            (prevCourses) =>
              prevCourses?.map((course) =>
                course.CourseId === courseId ? { ...course, CourseName: name, Description: description } : course
              ) || null
          );
        } catch (error) {
          console.error(error);
        }
      };
    
      const UpdateTopic = async (topicId: number, courseId: number, name: string, details: string) => {
        try {
          await SendRequest(`${domainAddress}/backend/api/topic.php`, "PUT", {
            id: topicId,
            course_id: courseId,
            name,
            details,
          });
          setCourses(
            (prevCourses) =>
              prevCourses?.map((course) => {
                if (course.CourseId === courseId) {
                  const updatedTopics = course.topics?.map((topic) =>
                    topic.topicId === topicId ? { ...topic, name, details } : topic
                  );
                  return { ...course, topics: updatedTopics };
                }
                return course;
              }) || null
          );
        } catch (error) {
          console.error(error);
        }
      };
    
      const UpdateSection = async (sectionId: number, name: string) => {
        try {
          await SendRequest(`${domainAddress}/backend/api/section`, "PUT", { id: sectionId, name });
          setSections(
            (prevSections) =>
              prevSections?.map((section) =>
                section.id === sectionId ? { ...section, sectionName: name } : section
              ) || null
          );
        } catch (error) {
          console.error(error);
        }
      };
    
      const UpdateTemplate = async (templateId: number, type: string, context: string) => {
        try {
          await SendRequest(`${domainAddress}/backend/api/template`, "PUT", { id: templateId, type, context });
          setTemplates(
            (prevTemplates) =>
              prevTemplates?.map((template) =>
                template.id === templateId ? { ...template, type, context } : template
              ) || null
          );
        } catch (error) {
          console.error(error);
        }
      };
    
      const UpdateTopicSection = async (topicId: number, sectionId: number, sequence: number) => {
        try {
          await SendRequest(`${domainAddress}/backend/api/topicSection`, "PUT", {
            topic_id: topicId,
            section_id: sectionId,
            sequence,
          });
          setTopicSections(
            (prevTopicSections) =>
              prevTopicSections?.map((ts) =>
                ts.topic_id === topicId && ts.section_id === sectionId ? { ...ts, sequence } : ts
              ) || null
          );
        } catch (error) {
          console.error(error);
        }
      };
    
      const UpdateSectionTemplate = async (sectionId: number, templateId: number, sequence: number) => {
        try {
          await SendRequest(`${domainAddress}/backend/api/sectionTemplate`, "PUT", {
            section_id: sectionId,
            template_id: templateId,
            sequence,
          });
          setSectionTemplates(
            (prevSectionTemplates) =>
              prevSectionTemplates?.map((st) =>
                st.section_id === sectionId && st.template_id === templateId ? { ...st, sequence } : st
              ) || null
          );
        } catch (error) {
          console.error(error);
        }
      };

    return {
        UpdateCourse,
        UpdateTopic,
        UpdateSection,
        UpdateTemplate,
        UpdateTopicSection,
        UpdateSectionTemplate,
    };
};