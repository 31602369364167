import React from "react";
import { useParams } from "react-router-dom";
import style from "./didactic.module.css";
import Footer from "../Footer";
import Header from "./HeaderDidactic";
import { useCourses, Template } from "../../CoursesContext";
import {
  TextSection,
  LinkSection,
  ImageSection,
  CodeBlock,
  DownloadSection,
} from "./sectionTemplates";

function TopicDetail() {
  const { courseName, topicName } = useParams<{
    courseName: string;
    topicName: string;
  }>();
  const {
    courses,
    sections,
    topicSections,
    sectionTemplates,
    templates,
    loading,
    error,
  } = useCourses();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!courseName || !topicName) return <div>No course or topic found</div>;

  const decodedCourseName = decodeURI(courseName);
  const decodedTopicName = decodeURI(topicName);

  // const numericCourseId = Number(courseId);
  // if (isNaN(numericCourseId)) return <div>Invalid course ID</div>;
  // const numericTopicId = Number(topicId);
  // if (isNaN(numericTopicId)) return <div>Invalid topic ID</div>;

  const course = courses?.find((c) => c.CourseName === decodedCourseName);
  if (!course) return <div>No course found</div>;

  const topic = course.topics?.find((t) => t.name === decodedTopicName);
  if (!topic) return <div>No topic found</div>;

  // console.log('Course:', course);
  // console.log('Topic:', topic);
  // console.log('All TopicSections:', topicSections);
  // console.log('All Sections: ', sections);

  const relatedSectionIds = topicSections
    ?.filter((ts) => ts.topic_id === topic.topicId)
    .map((ts) => ts.section_id);
  // console.log('Related Section IDs:', relatedSectionIds);

  const relatedSections = sections
    ?.filter((s) => relatedSectionIds?.includes(s.id))
    ?.sort((a, b) => {
      const sectionA = topicSections?.find((ts) => ts.section_id === a.id);
      const sectionB = topicSections?.find((ts) => ts.section_id === b.id);

      if (sectionA?.sequence && sectionB?.sequence) {
        return sectionA.sequence - sectionB.sequence;
      }
      return 0;
    });
  // console.log('Related Sections:', relatedSections);

  const links = [
    { name: "Strona główna", url: "/" },
    { name: "Wszystkie kursy", url: "/course" },
    { name: course.CourseName, url: `/course/${decodedCourseName}` },
    { name: topic.name, url: `/course/${encodeURI(course.CourseName)}/topic/${encodeURI(topicName)}` },
  ];

  const renderTemplate = (type: string, context: any) => {
    switch (type) {
      case "text":
        return <TextSection context={context} />;
      case "link":
        return <LinkSection context={context} />;
      case "image":
        return <ImageSection context={context} />;
      case "codeBlock":
        const codeContext = context as [string, string][];
        return <CodeBlock context={codeContext} />;
      case "download":
        return <DownloadSection context={context} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header links={links} />
      <div className="container">
        <div className="row justify-content-center">
          <div
            className={`${style.didacticBlock} col-12 col-md-12 text-center rounded mt-4 mb-4`}
          >
            <h1>{topic.name}</h1>
          </div>
          <div className="w-100"></div>
          <div className={`col-12 col-md-10 align-items-center rounded mb-4`}>
            <div className={`${style.didacticBlock} text-center rounded mb-4`}>
              <p><b>Opis:</b> {topic.details}</p>
            </div>
            {relatedSections && relatedSections.length > 0 ? (
              relatedSections.map((section, index) => {
                // Filter templates related to a section and sort them by sequence
                const sectionTemplatesData = sectionTemplates
                  ?.filter((st) => st.section_id === section.id)
                  .sort((a, b) => (a.sequence || 0) - (b.sequence || 0)) // Sorting by sequence
                  .map((st) => templates?.find((t) => t.id === st.template_id))
                  .filter(Boolean) as Template[];

                return (
                  <div
                    key={index}
                    className={`${style.didacticBlock} rounded mt-4 mb-4`}
                  >
                    <h4 className="text-center">{section.sectionName}</h4>
                    {sectionTemplatesData.length > 0 ? (
                      sectionTemplatesData.map((template, i) => {
                        // console.log("Template.type: ", template.type);
                        // console.log("Template.context: ", template.context);
                        let parsedContext: string[] = [];
                        // Parsowanie kontekstu z JSON, jeśli jest w formie stringu
                        parsedContext =
                          typeof template.context === "string"
                            ? JSON.parse(template.context)
                            : template.context;
                        return (
                          <div key={i}>
                            {renderTemplate(template.type, parsedContext)}
                          </div>
                        );
                      })
                    ) : (
                      <div>No templates found</div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>No sections found</div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TopicDetail;
