import React, { useState, useEffect } from "react";
import {
  Template,
  useCourses,
} from "../../../../CoursesContext";

interface TemplateListProps {
  selectedSection: number;
  setSelectedSection: React.Dispatch<React.SetStateAction<number | null>>;
  templateContext: { [key: number]: string };
  templateType: { [key: number]: string };
  handleTemplateTypeChange: (templateId: number, type: string) => void;
  handleTemplateContextChange: (templateId: number, context: string) => void;
}

const TemplateList: React.FC<TemplateListProps> = ({
  selectedSection,
  setSelectedSection,
  templateContext,
  templateType,
  handleTemplateTypeChange,
  handleTemplateContextChange,
}) => {
  const {
    sectionTemplates,
    templates,
    UpdateTemplate,
    UpdateSectionTemplate,
    DeleteSectionTemplate,
    DeleteTemplate,
  } = useCourses();

  // const [sectionName, setSectionName] = useState<string>("");
  const [selectedSequenceTemplate, setSelectedSequenceTemplate] = useState<{[templateId: number]: number | null;}>({});

  useEffect(() => {
    if (selectedSection) {
      const relatedSectionTemplates = sectionTemplates?.filter(
        (st) => st.section_id === selectedSection
      );

      if (relatedSectionTemplates) {
        const sequenceMap: { [templateId: number]: number | null } = {};
        relatedSectionTemplates.forEach((st) => {
          sequenceMap[st.template_id] = st.sequence ?? null;
        });
        setSelectedSequenceTemplate(sequenceMap);
      } else {
        setSelectedSequenceTemplate({});
      }
    }
  }, [selectedSection, sectionTemplates]);

  const templatesForSection = sectionTemplates
    ?.filter((st) => st.section_id === selectedSection)
    .map((st) => ({
      ...templates?.find((template) => template.id === st.template_id),
      uniqueKey: `${st.template_id}-${st.section_id}`,
    }))
    .filter(
      (template): template is Template & { uniqueKey: string } =>
        template !== undefined
    );

  const handleEditTemplate = async (templateId: number) => {
    const template = templates?.find((t) => t.id === templateId);
    const type = templateType[templateId] || template?.type;
    let context = templateContext[templateId] || template?.context;
    const sequence = selectedSequenceTemplate?.[templateId];

    console.log("Selected Section:", selectedSection);
    console.log("Template ID:", template?.id);
    console.log("Template Type:", type);
    console.log("Template Context:", context);
    console.log("Template Sequence:", sequence);

    if (templateId && type && context) {
      await UpdateTemplate(templateId, type, context);
      if (selectedSection && template?.id && sequence) {
        await UpdateSectionTemplate(selectedSection, template.id, sequence);
      }
      setSelectedSection(null);
      // setSectionName("");
    }
  };

  const handleDeleteTemplate = async (sectionId: number, templateId: number) => {
    console.log("Delete template with ID:", templateId);

    // Find all section templates associated with the template
    const relatedSectionTemplates = sectionTemplates?.filter(
      (st) => st.template_id === templateId
    );

    // Delete each section template associated with the template
    if (relatedSectionTemplates) {
      for (const st of relatedSectionTemplates) {
        await DeleteSectionTemplate(st.section_id, st.template_id);
      }
    }

    // Call the DeleteTemplate function from CourseContext
    await DeleteTemplate(templateId);

    setSelectedSection(null);
  };

  const handleDeleteTemplateAssignment = async (templateId: number) => {
    console.log("Delete assignment template with ID: ", templateId);

    // Find all section templates associated with the template
    const relatedSectionTemplates = sectionTemplates?.filter(
      (st) => st.template_id === templateId
    );

    // Delete each section template associated with the template
    if (relatedSectionTemplates) {
      for (const st of relatedSectionTemplates) {
        await DeleteSectionTemplate(st.section_id, st.template_id);
      }
    }
  };

  return (
    <div className="mb-3">
      <div className="text-center">
        <p className="fs-3 fw-bold">Działy</p>
      </div>
      {templatesForSection && templatesForSection.length > 0 ? (
        templatesForSection.map((template) => (
          <div key={template.uniqueKey} className="">
            <div className="">
              {/* Dropdown for selecting template type */}
              <label
                htmlFor={`templateType-${template.id}`}
                className="form-label"
              >
                Typ działu
              </label>
              <select
                id={`templateContext-${template.id}`}
                className="form-select mb-2"
                value={templateType[template.id] || template.type}
                onChange={(e) =>
                  handleTemplateTypeChange(template.id, e.target.value)
                }
              >
                <option value="text">text</option>
                <option value="link">link</option>
                <option value="image">image</option>
                <option value="codeBlock">codeBlock</option>
                <option value="download">download link</option>
              </select>
              <label
                htmlFor={`templateContext-${template.id}`}
                className="form-label"
              >
                Treść:
              </label>
              <textarea
                id={`templateContext-${template.id}`}
                className="form-control"
                value={
                  templateContext[template.id] ||
                  (typeof template.context === "string"
                    ? template.context
                    : JSON.stringify(template.context))
                }
                onChange={(e) =>
                  handleTemplateContextChange(template.id, e.target.value)
                }
              />

              <label
                htmlFor={`templateSequence-${template.id}`}
                className="form-label"
              >
                Kolejność:{" "}
              </label>
              <input
                id={`sequenceTemplate-${template.id}`}
                type="number"
                className="form-control w-auto"
                value={selectedSequenceTemplate[template.id] || ""}
                onChange={(e) => {
                  const newValue =
                    e.target.value !== "" ? parseInt(e.target.value) : null;
                  setSelectedSequenceTemplate((prevState) => ({
                    ...prevState,
                    [template.id]: newValue,
                  }));
                }}
              />

              <div className="mb-3 text-center">
                <button
                  type="button"
                  onClick={() => handleEditTemplate(template.id)}
                  className="btn btn-primary m-2"
                >
                  Edytuj dział
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteTemplateAssignment(template.id)}
                  className="btn btn-danger m-2"
                >
                  Usuń przypisanie do sekcji
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteTemplate(selectedSection, template.id)}
                  className="btn btn-danger m-2"
                >
                  Usuń dział
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No templates available for this section.</p>
      )}
    </div>
  );
};

export default TemplateList;
